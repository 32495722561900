



































































































window.onload = function(){
  var hasTU = ((document.querySelector('.aPoint-select') as HTMLElement).getAttribute('style') + "").indexOf('display: none;') >= 0;
  if(hasTU) {
    const d = document.querySelector('.accountingPointNumber') as HTMLElement;
    d.style.display = 'none';
  }
}

import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";
import { appealForm } from "@/assets/scripts/form-fields/main";
import XForm from "@/components/SimpleForm.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import { validationMixin } from "vuelidate";
import { AppealThemes, AppealThemeTypes } from "@/models/appeals";
import { OrganizationAgent } from "@/models/organization";
import { AccountingPoint } from "@/models/accounting-point";
import Page from "@/components/for-page-grid/Page.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import { appealsAcceptedExtensions } from "@/assets/scripts/utils";

const { values, attrs, validations, errors } = appealForm;

@Component({
  components: {
    Page,
    XForm,
    XNotice,
  },
  computed: {
    ...errors,
    ...mapGetters({
      email: "user/email",
      orgContactAgent: "organization/agentList",
      nameOrg: "organization/nameOrg",
      contractId: "contract/id",
      orgIdOfContractId: "contract/orgIdOfContractId",
      themeName: "appeals/themeName",
      themeId: "appeals/themeId",
      themeTypes: "appeals/themeTypes",
      themeTypesId: "appeals/themeTypesId",
      accountingPoints: "accountingPoints/allPoints",

    }),
  },
  methods: {
    ...mapMutations("organization", {
      setAgents: "setAgents",
    }),
  },
  mixins: [validationMixin],
  validations,
})
class AppealAddingForm extends Mixins(AppApiMixin, XForm) {
  [x: string]: any

  values = values
  defaultValues: {[x: string]: any} = {}
  allFieldAttrs = attrs;
  initialSelectValue = 'Выберите тему обращения'
  initialSelectValue2 = 'Выберите вопрос темы обращения'
  initialSelectValue3 = 'Выберите точку учета'
  noAPointsForSelect = 'Точки учета отсутствуют'
  file: Array<File> | [];
  useSuccessAlert = false
  submitSuccessText = '';
  useSubmitButtonLoading = false;
  submitError = '';
  submitErrorText = '';

  themeArr: {[x: string]: number} = {}
  themeTypeArr: {[x: string]: number} = {}
  pointsArr: {[x: string]: number} = {}
  activeThemeId = 1;
  submitSuccess = '';
  themeTypeItems = [];
  firstMessage = "";
  temp_mas = [];
  file_count = 0;
  hasThemeType = true;

  @Prop({ default: false }) readonly show!: boolean;

  mounted() {
    this.hasthemeType = true;
    this.values = {...this.values, email: this.userEmail, appealThemes: this.initialSelectValue};
    this.defaultValues = {...this.values, email: this.userEmail, appealThemes: this.initialSelectValue};

    this.getAllAppealThemes(this.contractId);
    this.getOrgAgents(this.orgIdOfContractId,(agents: OrganizationAgent[]) => {
      this.setAgents(agents);
      const author_agents = this.orgContactAgent.map((name: OrganizationAgent) => name.фио);
      let agents_str = '';
      if (author_agents.length > 0) {
        author_agents.forEach((item,i) => {
          agents_str += author_agents[i] + ', ';
        });
        agents_str = agents_str.replace(/[\s,]+$/g,'').trim();
      } else {
        agents_str = this.nameOrg;
      }
      this.temp_mas.push(agents_str);
    });

    const d = document.querySelector('.themeType-select') as HTMLElement;
    const dprev = document.querySelector('.themeType-select').previousElementSibling as HTMLElement;
    d.style.display = 'none';
    dprev.style.display = 'none';

  }

  public get accountingPointsForSelect() {
    const aPoints = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.названиету) : [];
    const aPoints_ids = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.лицевой) : [];

    aPoints.forEach((item, index) => {
      this.pointsArr[item] = aPoints_ids[index];
    });

    this.hasThemeType = aPoints.length != 0; //Нет ТУ - скрыть поле

    if (aPoints.length > 0) {
      if(aPoints[0] != '') {
        attrs['accountingPointNumber']['label'] = 'Точка учета';
        return [...aPoints];
      }
    } else {
      attrs['accountingPointNumber']['label'] = '';
      return [];
    }
  }

  public get themesForSelect() {
    const themes = this.themeName ? this.themeName.map((name: AppealThemes) => name.название) : [];
    const themeArr_ids = this.themeName ? this.themeName.map((name: AppealThemes) => name.$номерЗаписи) : [];

    themes.forEach((item, index) => {
      this.themeArr[item] = themeArr_ids[index];
    });

    return [...themes]
  }
  get themeTypesForSelect() {
    const themeTypes = this.themeTypes ? this.themeTypes.map((name: AppealThemeTypes) => name.название) : [];
    const themeTypes_mas_ids = this.themeTypes ? this.themeTypes.map((name: AppealThemeTypes) => name.$номерЗаписи) : [];

    themeTypes.forEach((item:number, index:number) => {
      this.themeTypeArr[item] = themeTypes_mas_ids[index];
    });

    return [...themeTypes];
  }
  onSelectTheme() {
    for (let key in this.themeArr) {
      if (key == this.values.typeAppeal) {
        this.activeThemeId = this.themeArr[key];
      }
    }

    const theme_types = this.getAllAppealThemeTypes(this.activeThemeId, this.contractId);
    theme_types.then((result) => {
      this.themeTypeItems = result;
      if (this.themeTypeItems.length == 0) {
        const d = document.querySelector('.themeType-select') as HTMLElement;
        const dprev = document.querySelector('.themeType-select').previousElementSibling as HTMLElement;
        d.style.display = 'none';
        dprev.style.display = 'none';
      } else {
        const d = document.querySelector('.themeType-select') as HTMLElement;
        const dprev = document.querySelector('.themeType-select').previousElementSibling as HTMLElement;
        d.style.display = 'unset';
        dprev.style.display = 'unset';
      }

    });
  }

  get userEmail() {
    return this.email
  }

  public log(...data: any) {
    data.forEach((m:any) => console.log(m))
  }

  onFileChanged(files: Array<File>) {
    this.file = files;
  }

  async submitForm() {
    this.useSuccessAlert = false
    this.useSubmitButtonLoading = true
    var canSend = true;
    const formData = new FormData

    for (let key in this.values) {
      if (key == 'typeAppeal') {
        formData.append(key, this.themeArr[this.values[key]] + "");
      }
      if (key == 'typeAppeal2') {
        if (this.values["typeAppeal2"] != '' && this.values["typeAppeal2"] != undefined) {
          formData.append(key, this.themeTypeArr[this.values[key]] + "");
        }
      }
      if (key == 'accountingPointNumber') {
        if (this.pointsArr && this.pointsArr[this.values[key]] != undefined) {
          formData.append(key, this.pointsArr[this.values[key]] + "");
        }
      }
      /*
      // TODO сделать без промежуточных переменных
      if (key == 'file') {
        // если есть файлы, прикрепляем
        if (this.values[key]) {
          for (let i = 0; i < (this.values[key] || []).length; i++) {
            // formData.append(key + '[' + i + ']', this.values[key][i]);
            formData.append(key + '[]', this.values[key][i]);
          }
        }
      }*/
      if (key == 'file' && this.file) {
        if(this.file.length >= 20) {
          canSend = false;
          this.submitErrorText = this.submitError = 'Загружаемых файлов больше 20, вы загрузили: ' +
            this.file.length;
        }
        for (let fille of this.file) {
          var temp = fille;
          if (temp.size >= 3145728) {
            canSend = false;
            this.submitErrorText = this.submitError = 'Загруженный файл "' + temp['name']
              + '" превышает допустимый размер 3МБ, ваш: ' +
              (temp.size / (1024 * 1024)).toFixed(2) + 'МБ';
          }
          if (temp.size == 0) {
            canSend = false;
            this.submitErrorText = this.submitError = 'Загруженный файл пустой';
          }
          if (this.getAppealsAcceptedExtensions().indexOf(temp['name'].split('.').pop()) < 0) {
            canSend = false;
            this.submitErrorText = this.submitError = 'Загруженный файл "' + temp['name']
              + '" имеет неподдерживаемый формат: ' + temp['name'].split('.').pop();
          }
          if (canSend == true) {
            formData.append(key+this.file_count,fille);
            this.file_count++;
          }
          else {
            break;
          }
        }
      }

      if (key == 'message') {
        this.firstMessage = this.values["message"];
        formData.append(key, this.values[key]);
      }
    }

    formData.append('contractId', this.contractId);
    formData.append('author', this.temp_mas[0]);
    formData.append('file_count', String(this.file_count));

    if (canSend == true) {
      try {
        const data = await this.sendAppeal(formData)

        if (data && data.result && (data.appealId) != 0) {
          this.submitSuccess = 'Обращение успешно отправлено';
          this.submitSuccessText = 'Сообщение успешно отправлено';
          this.values = {...this.defaultValues}
          this.useSuccessAlert = true

          for (let key in this.values) {
            this.$v.values[key] && this.$v.values[key].$reset()
          }
          this.file = [];
          this.values['file'] = this.file;
          (this.$refs.f as HTMLInputElement).value = null;

          this.$emit('updateTable');
          this.$emit("update:show", false);
          this.submitError = '';
        }

      } catch (e) {
        this.submitError = e.data;
      }
    }
    this.submitSuccess = '';
    this.useSubmitButtonLoading = false
  }

  getAppealsAcceptedExtensions() { return appealsAcceptedExtensions() }
  getAppealsAcceptedExtensionsText() { return 'Доступны форматы: ' + appealsAcceptedExtensions() }
}

export default AppealAddingForm
